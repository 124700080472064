<template>
  <hub-tasks />
</template>

<script>
import Tasks from '@/components/search/List.vue';

export default {
  components: {
    'hub-tasks': Tasks
  }
};
</script>
